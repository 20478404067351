import React, { useEffect } from "react";
const StripePricingTable = () => {
useEffect(() => {
const script = document.createElement("script");
script.src = "https://js.stripe.com/v3/pricing-table.js";
script.async = true;
document.body.appendChild(script);
return () => {
document.body.removeChild(script);
};
}, []);
return React.createElement("stripe-pricing-table", {
"pricing-table-id": "prctbl_1O7j8qKcsjVD1zkXEQPRBTGB",
"publishable-key": "pk_live_51O4CvJKcsjVD1zkXam4oLsDeTTboCMOG0zrtvhYwAs2mXUOeYdnbY7oVAnnL0Ez6cWdWlpv4i2aVFMAp7zu9aFEf00jHOholgd",
});
};
export default StripePricingTable;
